import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Spinner,
  Flex,
  Input,
  Button,
  Heading,
  useToast,
  Image,
  Text,
} from "@chakra-ui/react";
import { useAuth } from "../../../utils/AuthContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import linkedinIcon from "../../../../assets/linkedin.png";
import LinkedInProfileReveal from "./LINK";

const LinkedInProfile = () => {
  const { workspacesData, currentUser } = useAuth();
  const [frontendApiKey, setFrontendApiKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isGrantingAccess, setIsGrantingAccess] = useState(false);
  const [error, setError] = useState(null); // To capture and display any error
  const toast = useToast();

  const showErrorToast = useCallback(
    (message) => {
      toast({
        title: "Error",
        description: message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    [toast]
  );

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        if (currentUser && workspacesData) {
          const workspaceId = Object.keys(workspacesData)[0];
          if (workspaceId) {
            const getFrontendApiKey = httpsCallable(
              functions,
              "getFrontendApiKey"
            );
            const response = await getFrontendApiKey({ workspaceId });
            setFrontendApiKey(response.data.apiKey);
          } else {
            throw new Error("No workspace available");
          }
        }
      } catch (err) {
        showErrorToast("Failed to fetch API key");
        console.error("Error fetching frontend API key:", err);
        setError("Error fetching API key.");
      } finally {
        setLoading(false);
      }
    };

    fetchApiKey();
  }, [currentUser, workspacesData, showErrorToast]);

  const handleLinkedinUrlChange = (e) => {
    setLinkedinUrl(e.target.value);
    setIsInvalidUrl(false);
    setError(null); // Reset error when typing a new URL
  };

  const extractLinkedinUsername = async () => {
    setIsSearching(true);
    setError(null); // Reset error before new search
    const regex = /https:\/\/(www\.)?linkedin\.com\/in\/([^/]+)/;
    const match = linkedinUrl.match(regex);

    if (match && match[2]) {
      setIsInvalidUrl(false);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/linkedin-profile-internal`,
          { username: match[2] },
          {
            headers: {
              Authorization: frontendApiKey,
              "Content-Type": "application/json",
            },
          }
        );
        setProfileData(response.data);
      } catch (apiError) {
        showErrorToast("Failed to fetch profile data from the server.");
        console.error("API error:", apiError);
        setError("Failed to fetch profile data.");
      } finally {
        setIsSearching(false);
      }
    } else {
      showErrorToast("Please enter a valid LinkedIn profile URL.");
      setProfileData(null);
      setIsInvalidUrl(true);
      setIsSearching(false);
    }
  };

  const handleGrantAccess = async (id) => {
    setIsGrantingAccess(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/linkedin-profile-reveal`,
        { id },
        {
          headers: {
            Authorization: frontendApiKey,
            "Content-Type": "application/json",
          },
        }
      );
      toast({
        title: "Access Granted",
        description: `Profile contact information revealed successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Re-fetch the profile data after granting access
      await extractLinkedinUsername();
    } catch (error) {
      showErrorToast("Failed to grant access. Please try again.");
      console.error("Grant access error:", error);
      setError("Failed to grant access.");
    } finally {
      setIsGrantingAccess(false);
    }
  };

  // Function to check if there are any hidden values
  const hasHiddenValues = (profileData) => {
    if (!profileData) return false;
    return (
      (profileData.emails &&
        profileData.emails.some((emailObj) =>
          Object.keys(emailObj).includes("hidden")
        )) ||
      (profileData.phones &&
        profileData.phones.some((phoneObj) =>
          Object.keys(phoneObj).includes("hidden")
        ))
    );
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" mt={50}>
      <Box width="700px" p={4} textAlign="left">
        <Flex alignItems="center">
          <Image src={linkedinIcon} alt="LinkedIn Icon" boxSize="40px" mr={4} />
          <Heading>LinkedIn Profile Lookup</Heading>
        </Flex>

        <Flex mt={4}>
          <Input
            width="100%"
            placeholder="Enter LinkedIn profile URL (ex. linkedin.com/in/username)"
            value={linkedinUrl}
            onChange={handleLinkedinUrlChange}
            size="lg"
            isInvalid={isInvalidUrl}
            errorBorderColor="red.500"
          />
          <Button
            ml={4}
            size="lg"
            colorScheme="blue"
            onClick={extractLinkedinUsername}
            leftIcon={!isSearching && <AiOutlineSearch />}
            isDisabled={isSearching}
            w="150px"
          >
            {isSearching ? <Spinner size="sm" /> : "Search"}
          </Button>
        </Flex>

        {/* Error Display */}
        {error && (
          <Text color="red.500" mt={4}>
            {error}
          </Text>
        )}

        {hasHiddenValues(profileData) && profileData?._id && (
          <Button
            mt={4}
            colorScheme="green"
            onClick={() => handleGrantAccess(profileData._id)}
            w="100%"
            isLoading={isGrantingAccess}
            isDisabled={isGrantingAccess}
          >
            Reveal Contact Information
          </Button>
        )}

        {profileData && <LinkedInProfileReveal profileData={profileData} />}
      </Box>
    </Flex>
  );
};

export default LinkedInProfile;
