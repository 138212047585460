import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
  HStack,
  Badge,
  Spinner,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { loadStripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '../../utils/AuthContext';
import Prices from './Prices';

// Initialize Stripe.js with your publishable key
const stripePromise = loadStripe('pk_live_51PmsSZAT4690HpL3HaiG2PgZp7ocqcaUfqXH8OKl8X7imu1vl2lMwQVdW0k6ftJ4nX5BlQ5QNOeb77fkduItGf9o00mrUQ6U52');

const PricingTable = () => {
  const [billingCycle, setBillingCycle] = useState('annual');
  const [loading, setLoading] = useState(null);
  const { currentUser, workspacesData } = useAuth();
  const [currentPriceId, setCurrentPriceId] = useState('');

  useEffect(() => {
    if (workspacesData) {
      const workspaceIds = Object.keys(workspacesData);
      if (workspaceIds.length > 0) {
        const currentWorkspace = workspacesData[workspaceIds[0]];
        if (currentWorkspace.priceId && currentWorkspace.priceId.length > 0) {
          console.log('Current Price ID:', currentWorkspace.priceId[0]);
          setCurrentPriceId(currentWorkspace.priceId[0]);
        }
      }
    }
  }, [workspacesData]);

  const pricingTiers = Prices({ billingCycle });

  const handleCheckout = async (priceId, limit) => {
    if (priceId === 'Free') {
      console.log('Free tier selected');
      return;
    }

    setLoading(priceId);
    const functions = getFunctions();
    const createCheckoutSession = httpsCallable(functions, 'createScoutStackCheckout');

    try {
      // Get the first workspace ID
      const workspaceId = Object.keys(workspacesData)[0];

      const { data } = await createCheckoutSession({
        priceId,
        customerId: workspacesData[workspaceId].customerId,
        userId: currentUser.uid,
        workspaceId,
        limit,
      });

      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId: data.sessionId });
    } catch (error) {
      console.error('Error during checkout:', error);
      setLoading(null);
    }
  };

  return (
    <Box>
      <Flex justify="center" mb={8}>
        <HStack bg="gray.50" p={2} borderRadius="full" borderWidth="1px">
          <Button
            colorScheme={billingCycle === 'annual' ? 'blue' : 'gray'}
            variant={billingCycle === 'annual' ? 'solid' : 'outline'}
            onClick={() => setBillingCycle('annual')}
            borderRadius="full"
            mr={1}
            borderWidth="0px"
            position="relative"
          >
            Annual Billing
            <Badge
              colorScheme="blue"
              borderRadius="full"
              px={2}
              py={1}
              fontSize="xs"
              fontWeight="bold"
              position="absolute"
              top="35px"
            >
              Save 20%
            </Badge>
          </Button>
          <Button
            colorScheme={billingCycle === 'monthly' ? 'blue' : 'gray'}
            variant={billingCycle === 'monthly' ? 'solid' : 'outline'}
            onClick={() => setBillingCycle('monthly')}
            borderRadius="full"
            borderWidth="0px"
          >
            Monthly Billing
          </Button>
        </HStack>
      </Flex>
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing={{ base: 8, lg: 4 }}
        justify="center"
      >
        {pricingTiers.map((tier) => (
          <Box
            key={tier.title}
            borderWidth="1px"
            rounded="xl"
            overflow="hidden"
            maxW="sm"
            w="full"
            position="relative"
          >
            {tier.isPopular && (
              <Box
                position="absolute"
                top={0}
                right={0}
                bg="blue.500"
                color="white"
                px={3}
                py={1}
                textTransform="uppercase"
                fontSize="xs"
                fontWeight="bold"
                roundedBottomLeft="md"
              >
                Most Popular
              </Box>
            )}
            <VStack p={8} h="full" spacing={6}>
              <VStack spacing={2} alignItems="center">
                <Heading as="h3" size="lg" color={tier.isPopular ? 'blue.500' : 'inherit'}>
                  {tier.title}
                </Heading>
                <Flex alignItems="baseline">
                  <Text fontSize="5xl" fontWeight="bold">
                    {tier.price}
                  </Text>
                  <Text fontSize="xl" color="gray.500" ml={1}>
                    /month
                  </Text>
                </Flex>
                <Text fontSize="sm" color="gray.500">
                  Billed {billingCycle === 'monthly' ? 'monthly' : 'annually'}
                </Text>
              </VStack>

              <Button
                w="full"
                mt="auto"
                fontWeight="bold"
                bg={tier.isPopular ? 'blue.500' : 'gray.200'}
                color={tier.isPopular ? 'white' : 'gray.700'}
                _hover={{ bg: tier.isPopular ? 'blue.600' : 'gray.300' }}
                onClick={() => handleCheckout(tier.priceId, tier.limit)}
                isDisabled={loading === tier.priceId || tier.priceId === currentPriceId}
              >
                {loading === tier.priceId ? (
                  <Spinner size="sm" />
                ) : tier.priceId === currentPriceId ? (
                  'Current Plan'
                ) : tier.title === 'Free' ? (
                  'Get Started'
                ) : (
                  'Choose Plan'
                )}
              </Button>

              <List spacing={3} flex="1">
                {tier.features.map((feature, index) => (
                  <ListItem key={index} display="flex" alignItems="center">
                    <CheckIcon color="blue.500" mr={2} />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </VStack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default PricingTable;
