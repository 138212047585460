import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyAI0BgFYNSg78i8yxeRdc8T_YcupwnPP1M",
  authDomain: "scoutstack-io.firebaseapp.com",
  projectId: "scoutstack-io",
  storageBucket: "scoutstack-io.appspot.com",
  messagingSenderId: "724235022743",
  appId: "1:724235022743:web:1c4f24b0377c6a2a53d4d2",
  measurementId: "G-H8MCL4BE61"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app);
const firestore = getFirestore(app);
const db = getFirestore(app);

export { auth, functions, firestore, db };