import React from 'react';
import { Box, Heading, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

const Lists = () => {
  const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4'];

  return (
    <Box p={4} maxW="md" borderWidth="1px" borderRadius="lg" boxShadow="lg" mx="auto" mt={6}>
      <Heading as="h3" size="lg" mb={4}>
        My List
      </Heading>
      <List spacing={3}>
        {items.map((item, index) => (
          <ListItem key={index}>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            {item}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default Lists;
