import React from 'react';
import { Box, Text, Button, VStack, HStack, Icon } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { MdOutlineSearch, MdOutlineBusiness, MdOutlineAttachMoney } from 'react-icons/md';

function Intent() {
  return (
    <Box textAlign="center" py={10}>
      <Text fontSize="2xl" fontWeight="bold" mb={6}>Choose Your Intent</Text>
      <VStack spacing={8}>
        <HStack spacing={8}>
          <Button
            leftIcon={<Icon as={MdOutlineSearch} w={8} h={8} color="red.500" />}
            as={Link}
            to="/dashboard/intent/job-search/"
            size="lg"
            variant="outline"
            bg="gray.200"
          >
            Job Search
          </Button>
          <Button
            leftIcon={<Icon as={MdOutlineBusiness} w={8} h={8} color="green.500" />}
            as={Link}
            to="/dashboard/intent/company-changes/"
            size="lg"
            variant="outline"
            bg="gray.200"
          >
            Company Changes
          </Button>
          <Button
            leftIcon={<Icon as={MdOutlineAttachMoney} w={8} h={8} color="blue.500" />}
            as={Link}
            to="/dashboard/intent/funding/"
            size="lg"
            variant="outline"
            bg="gray.200"
          >
            Funding
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
}

export default Intent;
