import React from 'react';
import { Box, Text, VStack, Heading } from '@chakra-ui/react';
import { useAuth } from '../utils/AuthContext';

function Contacts() {
  const { workspacesData, allowedDocuments } = useAuth();

  return (
    <Box p={4}>
      <Heading as="h1" size="xl" mb={4}>Workspaces and Allowed Documents</Heading>
      {Object.entries(workspacesData).map(([workspaceId, workspace]) => (
        <Box key={workspaceId} mb={6}>
          <Heading as="h2" size="lg" mb={2}>{workspace.name}</Heading>
          <Text mb={2}>Workspace ID: {workspaceId}</Text>
          <Heading as="h3" size="md" mb={2}>Allowed Documents:</Heading>
          {allowedDocuments[workspaceId] ? (
            <VStack align="start" spacing={2}>
              {Object.entries(allowedDocuments[workspaceId]).map(([docId, doc]) => (
                <Box key={docId}>
                  <Text fontWeight="bold">Document ID: {docId}</Text>
                  <Text>{JSON.stringify(doc, null, 2)}</Text>
                </Box>
              ))}
            </VStack>
          ) : (
            <Text>No allowed documents found for this workspace.</Text>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default Contacts;