import { Box, Heading, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const goToToolsPage = () => {
    navigate("/dashboard/tools");
  };

  return (
    <Box textAlign="left" p={4}>
      <Heading as="h1" size="xl" mb={4}>
        Welcome to ScoutStack!
      </Heading>
      <Text fontSize="lg" mb={6}>
        To get started, please use our tools.
      </Text>
      <Button colorScheme="blue" size="lg" onClick={goToToolsPage}>
        Go to Tools
      </Button>
    </Box>
  );
};

export default Home;
