import React, { useState } from 'react';
import { Box, Button, Text, VStack, HStack, Table, Thead, Tbody, Tr, Th, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

function Search() {
  const [activeTab, setActiveTab] = useState('people');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <HStack align="start" spacing={8}>
      <VStack align="start" spacing={4} borderRightWidth="1px" h="calc(100vh - 90px)" p={4}>
        <HStack p={2}>
          <Button
            variant="ghost"
            borderBottom="2px"
            borderBottomColor={activeTab === 'people' ? 'blue.500' : 'transparent'}
            borderRadius="none"
            _hover={{ borderBottomColor: 'gray.200' }}
            onClick={() => handleTabChange('people')}
          >
            People
          </Button>
          <Button
            variant="ghost"
            borderBottom="2px"
            borderBottomColor={activeTab === 'company' ? 'blue.500' : 'transparent'}
            borderRadius="none"
            _hover={{ borderBottomColor: 'gray.200' }}
            onClick={() => handleTabChange('company')}
          >
            Company
          </Button>
        </HStack>
        {activeTab === 'people' && (
          <Box>
            <Text>People Filters</Text>
            <VStack align="start" spacing={2}>
              {peopleFilters.map(filter => (
                <Menu key={filter}>
                  <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                    {filter}
                  </MenuButton>
                  <MenuList>
                    {/* Add menu items here */}
                    <MenuItem>Option 1</MenuItem>
                    <MenuItem>Option 2</MenuItem>
                    <MenuItem>Option 3</MenuItem>
                  </MenuList>
                </Menu>
              ))}
            </VStack>
          </Box>
        )}
        {activeTab === 'company' && (
          <Box>
            <Text>Company Filters</Text>
            {/* Add company filters here */}
          </Box>
        )}
      </VStack>
      <Box flex="1">
        <SearchResultsTable />
      </Box>
    </HStack>
  );
}

const peopleFilters = [
  'Lists',
  'Persona',
  'Email Status',
  'Job Titles',
  'Company',
  'Location',
  '# Employees',
  'Industry & Keywords',
  'Buying Intent',
  'Scores',
  'Territories',
  'Technologies',
  'Revenue',
  'Funding',
  'Job Postings',
  'Signals',
  'Email Opened'
];

function SearchResultsTable() {
  return (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Name</Th>
          <Th>Details</Th>
        </Tr>
      </Thead>
      <Tbody>
        {/* Table rows will be added dynamically here */}
      </Tbody>
    </Table>
  );
}

export default Search;
