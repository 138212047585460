import React, { useState } from 'react';
import {
  Box,
  Flex,
  Input,
  Button,
  FormControl,
  FormLabel,
  VStack,
  Text,
  Link,
  Image,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { auth, functions } from '../Firebase';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import logolong from '../assets/logolong.png';
import { FcGoogle } from "react-icons/fc";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const toast = useToast();

  const notifyExtension = (user) => {
    const event = new CustomEvent('auth_state_change', { detail: { user } });
    document.dispatchEvent(event);
  };

  const setUserCredentials = (user) => {
    const userDetails = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL
    };
    localStorage.setItem('user', JSON.stringify(userDetails));
    notifyExtension(userDetails);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setUserCredentials(user);
      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
      toast({
        title: 'Login Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  const handleGoogleSignIn = async () => {
    setError('');
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const createUserProfile = httpsCallable(functions, 'createUserProfile');
      await createUserProfile({
        userId: user.uid,
        email: user.email,
        firstName: user.displayName.split(' ')[0],
        lastName: user.displayName.split(' ').slice(1).join(' '),
        verified: true,
      });

      setUserCredentials(user);

      navigate('/dashboard');
    } catch (error) {
      setError(error.message);
      toast({
        title: 'Google Sign-In Failed',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'bottom',
      });
    }
  };

  return (
    <Flex minHeight="100vh" width="full">
      <Box width="60%" bg="gray.200">
        <Flex minHeight="100vh" width="full" align="center" justifyContent="center">
          <Box textAlign="center" width="500px">
            <Box my={4} textAlign="center" w="150px" mx="auto">
              <Link href="https://scoutstack.io" isExternal>
                <Image src={logolong} alt="Logo" mb={8} />
              </Link>
            </Box>

            <Box p={6} bg="white" boxShadow="lg">
              <Flex justify="space-between" mb={6}>
                <Button
                  variant="unstyled"
                  onClick={() => navigate('/login')}
                  _hover={{ borderBottom: '2px solid', borderColor: 'blue.500' }}
                  borderBottom="2px solid"
                  borderBottomColor="blue.500"
                  width="full"
                  borderRadius="none"
                >
                  Login
                </Button>
                <Button
                  variant="unstyled"
                  onClick={() => navigate('/signup')}
                  _hover={{ borderBottom: '2px solid', borderColor: 'blue.500' }}
                  borderBottom="2px solid lightgray"
                  width="full"
                  borderRadius="none"
                >
                  Sign Up
                </Button>
              </Flex>
              <Heading mb={4}>Login</Heading>
              <Box as="form" onSubmit={handleLogin}>
                <VStack spacing={4} align="flex-start">
                  <FormControl isInvalid={!!error}>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="you@example.com"
                    />
                  </FormControl>
                  <FormControl isInvalid={!!error}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      placeholder="Enter your password"
                    />
                  </FormControl>
                  <Button
                    width="full"
                    mt={4}
                    type="submit"
                    colorScheme="blue"
                  >
                    Login
                  </Button>
                  <Button
                    width="full"
                    mt={4}
                    onClick={handleGoogleSignIn}
                  >
                    <Flex align="center">
                      <FcGoogle />
                      <Text ml={2}>Continue with Google</Text>
                    </Flex>
                  </Button>
                </VStack>
              </Box>
              <Box mt={6}>
                <Text color="gray.600">
                  Forgot your password?{' '}
                  <Link color="blue.500" href="/reset">
                    Reset it here
                  </Link>
                </Text>
              </Box>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box width="40%" bg="blue.500" />
    </Flex>
  );
}

export default Login;