import React from 'react';
import { Box, SimpleGrid, Image, Text, Link as ChakraLink, Tooltip } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import chromeIcon from '../../../assets/chrome.svg';
import zillowIcon from '../../../assets/zillow.svg';
import linkedinIcon from '../../../assets/linkedin.png';

const toolsData = [
  {
    name: "LinkedIn Profile Lookup",
    description: "Get professional and personal emails and phone numbers.",
    icon: linkedinIcon,
    link: "/dashboard/tools/linkedin-profile",
    colorScheme: "purple",
    disabled: false,
    isExternal: false
  },
  {
    name: "Zillow Homes Lookup",
    description: "Find information about homeowners right from Zillow.",
    icon: zillowIcon,
    link: "/dashboard/tools/zillow-home",
    colorScheme: "blue",
    disabled: false,
    isExternal: false
  },
  {
    name: "Chrome Extension",
    description: "Download the extension and find data at your fingertips.",
    icon: chromeIcon,
    link: "https://chrome.google.com/webstore",
    colorScheme: "teal",
    disabled: true,
    isExternal: true
  }
];

const Tools = () => {
  return (
    <Box p="4">
      <SimpleGrid columns={[1, null, 5]} spacing={4}>
        {toolsData.map((tool, index) => (
          tool.disabled ? (
            <Tooltip label="Coming soon!" placement="bottom" key={index}>
              <Box 
                bg="#f0f0f0" 
                p={6} 
                rounded="lg" 
                h="300px" 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                border="1px solid" 
                borderColor="gray.200"
                transition="transform 0.2s ease-in-out, background 0.3s ease"
                textAlign="center"
                cursor="not-allowed"
              >
                <Image src={tool.icon} alt={`${tool.name} Icon`} boxSize="60px" mb={4} opacity={0.5} />
                <Text fontSize="2xl" fontWeight="bold" mb={3}>
                  {tool.name}
                </Text>
                <Text fontSize="lg" color="gray.600" textAlign="center">
                  {tool.description}
                </Text>
              </Box>
            </Tooltip>
          ) : tool.isExternal ? (
            <ChakraLink 
              href={tool.link} 
              isExternal 
              key={index} 
              style={{ textDecoration: 'none' }}
              _hover={{ textDecoration: 'none' }}
            >
              <Box 
                bg="white" 
                p={6} 
                rounded="lg" 
                h="300px" 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                border="1px solid" 
                borderColor="gray.200"
                transition="transform 0.2s ease-in-out, background 0.3s ease"
                _hover={{ bg: '#f0f0f0' }}
                textAlign="center"
                cursor="pointer"
              >
                <Image src={tool.icon} alt={`${tool.name} Icon`} boxSize="60px" mb={4} />
                <Text fontSize="2xl" fontWeight="bold" mb={3}>
                  {tool.name}
                </Text>
                <Text fontSize="lg" color="gray.600" textAlign="center">
                  {tool.description}
                </Text>
              </Box>
            </ChakraLink>
          ) : (
            <RouterLink 
              to={tool.link} 
              key={index} 
              style={{ textDecoration: 'none' }}
            >
              <Box 
                bg="white" 
                p={6} 
                rounded="lg" 
                h="300px" 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="center" 
                border="1px solid" 
                borderColor="gray.200"
                transition="transform 0.2s ease-in-out, background 0.3s ease"
                _hover={{ bg: '#f0f0f0' }}
                textAlign="center"
                cursor="pointer"
              >
                <Image src={tool.icon} alt={`${tool.name} Icon`} boxSize="60px" mb={4} />
                <Text fontSize="2xl" fontWeight="bold" mb={3}>
                  {tool.name}
                </Text>
                <Text fontSize="lg" color="gray.600" textAlign="center">
                  {tool.description}
                </Text>
              </Box>
            </RouterLink>
          )
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Tools;
