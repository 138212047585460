import React from 'react';
import { VStack, HStack, Text, Button, Box, Badge } from '@chakra-ui/react';
import { useAuth } from '../../utils/AuthContext';
import { signOut } from 'firebase/auth';
import { auth } from '../../../Firebase';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const { currentUser, userProfile } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const firstLetter = currentUser ? currentUser.displayName.charAt(0) : '';

  return (
    <VStack spacing={4} align="left">
      {currentUser && userProfile ? (
        <>
          <Box
            rounded="full"
            minW={0}
            bg="gray.200"
            color="black"
            h="80px"
            w="80px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text fontSize="2xl" fontWeight="bold" textDecoration="none">
              {firstLetter}
            </Text>
          </Box>
          <Text fontSize="2xl" fontWeight="bold">
            Welcome, {currentUser.displayName}
          </Text>
          <HStack spacing={2}>
            <Text fontSize="md" fontWeight="bold" color="gray.600">
              Email:
            </Text>
            <Text fontSize="md">
              {currentUser.email}
            </Text>
          </HStack>
          <HStack spacing={2}>
            <Text fontSize="md" fontWeight="bold" color="gray.600">
              User ID:
            </Text>
            <Text fontSize="md">
              {currentUser.uid}
            </Text>
          </HStack>
          <HStack spacing={2}>
            <Text fontSize="md" fontWeight="bold" color="gray.600">
              Verified:
            </Text>
            <Badge colorScheme={userProfile.verified ? 'green' : 'red'}>
              {userProfile.verified ? 'Yes' : 'No'}
            </Badge>
          </HStack>
          <Button
            mt={4}
            colorScheme="red"
            onClick={handleLogout}
            w="100px"
          >
            Logout
          </Button>
        </>
      ) : (
        <Text mt={4} fontSize="lg" color="gray.600">
          Loading...
        </Text>
      )}
    </VStack>
  );
}

export default Profile;
