// components/Workspace.js
import React from 'react';
import { useAuth } from '../../utils/AuthContext';
import { Box, Text, Code, VStack } from '@chakra-ui/react';

const Workspace = () => {
  const { currentUser, workspacesData } = useAuth();

  if (!currentUser) {
    return <Text>Loading...</Text>;
  }

  const workspaceIds = Object.keys(workspacesData);
  if (workspaceIds.length === 0) {
    return <Text>No workspaces found.</Text>;
  }

  return (
    <Box>
      <Text fontSize="2xl" fontWeight="bold" mb={4}>Workspace Data</Text>
      <VStack align="start" spacing={4}>
        {workspaceIds.map((workspaceId) => (
          <Box key={workspaceId} p={4} borderWidth={1} borderRadius="md" width="100%">
            <Text>Workspace ID: {workspaceId}</Text>
            <Code p={4} display="block" whiteSpace="pre-wrap">
              {JSON.stringify(workspacesData[workspaceId], null, 2)}
            </Code>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default Workspace;
