import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function JobSearch() {
  return (
    <Box>
      <Text>Job Search Intent</Text>
    </Box>
  );
}

export default JobSearch;
