import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Spinner,
  Flex,
  Input,
  Button,
  Heading,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useAuth } from "../../../utils/AuthContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../Firebase";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import zillowIcon from "../../../../assets/zillow.svg";
import ZillowHomeReveal from "./ZillowHomeReveal"; // Import the new component
import { useNavigate } from "react-router-dom";

const ZillowHome = () => {
  const { workspacesData, currentUser } = useAuth();
  const [frontendApiKey, setFrontendApiKey] = useState(null);
  const [loading, setLoading] = useState(true);
  const [zillowUrl, setZillowUrl] = useState("");
  const [homeData, setHomeData] = useState(null);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isGrantingAccess, setIsGrantingAccess] = useState(false);
  const toast = useToast();
  const navigate = useNavigate(); 

  const showErrorToast = useCallback(
    (message) => {
      toast({
        title: "Error",
        description: message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    [toast]
  );

  useEffect(() => {
    const fetchApiKey = async () => {
      if (currentUser && workspacesData) {
        const workspaceId = Object.keys(workspacesData)[0];
        if (workspaceId) {
          const getFrontendApiKey = httpsCallable(
            functions,
            "getFrontendApiKey"
          );
          try {
            const response = await getFrontendApiKey({ workspaceId });
            setFrontendApiKey(response.data.apiKey);
          } catch (err) {
            showErrorToast("Failed to fetch API key");
            console.error("Error fetching frontend API key:", err);
          } finally {
            setLoading(false);
          }
        } else {
          showErrorToast("No workspace available");
          setLoading(false);
        }
      }
    };

    fetchApiKey();
  }, [currentUser, workspacesData, showErrorToast]);

  const handleZillowUrlChange = (e) => {
    setZillowUrl(e.target.value);
    setIsInvalidUrl(false);
  };

  const extractZillowAddress = async () => {
    setIsSearching(true);
    const cleanedUrl = zillowUrl.replace(/,/g, "").replace(/_rb/g, ""); // Remove commas and _rb from the URL
  
    const urlFormats = [
      /https:\/\/(www\.)?zillow\.com\/homedetails\/([^/]+)\/([^/]+)_zpid\//,
      /https:\/\/(www\.)?zillow\.com\/homes\/([^/]+)\/([^/]+)_zpid\//,
      /https:\/\/(www\.)?zillow\.com\/homedetails\/([^/]+)\//,
      /https:\/\/(www\.)?zillow\.com\/homes\/([^/]+)\//,
    ];
  
    let match;
    for (const regex of urlFormats) {
      match = cleanedUrl.match(regex);
      if (match) break;
    }
  
    if (match) {
      const addressPart = match[2] || match[3];
      setIsInvalidUrl(false);
  
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_API_URL}/zillow-home-internal`,
          { address: addressPart, fullUrl: cleanedUrl },
          {
            headers: {
              Authorization: frontendApiKey,
              "Content-Type": "application/json",
            },
          }
        );
        setHomeData(response.data);
      } catch (apiError) {
        showErrorToast("Failed to fetch home data from the server.");
        console.error("API error:", apiError);
      } finally {
        setIsSearching(false);
      }
    } else {
      showErrorToast("Please enter a valid Zillow home URL.");
      setHomeData(null);
      setIsInvalidUrl(true);
      setIsSearching(false);
    }
  };  

  const handleGrantAccess = async (id) => {
    setIsGrantingAccess(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_API_URL}/zillow-home-reveal`,
        { id },
        {
          headers: {
            Authorization: frontendApiKey,
            "Content-Type": "application/json",
          },
        }
      );
      toast({
        title: "Access Granted",
        description: `Access granted successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      await extractZillowAddress();
    } catch (error) {
      showErrorToast("Please upgrade your plan to access this data.");
      console.error("Grant access error:", error);

      // Use navigate to programmatically go to the billing settings page
      navigate("/dashboard/settings/billing");
    } finally {
      setIsGrantingAccess(false);
    }
  };
  

  // Function to check if there are any hidden values
  const hasHiddenValues = (homeData) => {
    if (!homeData || !homeData.people) return false;
    return homeData.people.some((person) => {
      return (
        person.phone === "hidden" ||
        person.email === "hidden" ||
        person.primaryPhone?.number === "hidden" ||
        person.otherPhones?.some((phone) => phone.number === "hidden") ||
        person.other_emails?.some((email) => email === "hidden")
      );
    });
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" height="80vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <Flex justifyContent="center" mt={50}>
      <Box width="700px" p={4} textAlign="left">
        <Flex alignItems="center">
          <Image src={zillowIcon} alt="Zillow Icon" boxSize="40px" mr={4} />
          <Heading>Zillow Home Lookup</Heading>
        </Flex>

        <Flex mt={4}>
          <Input
            width="100%"
            placeholder="Enter Zillow home URL (ex. zillow.com/homedetails/address)"
            value={zillowUrl}
            onChange={handleZillowUrlChange}
            size="lg"
            isInvalid={isInvalidUrl}
            errorBorderColor="red.500"
          />
          <Button
            ml={4}
            size="lg"
            colorScheme="blue"
            onClick={extractZillowAddress}
            leftIcon={!isSearching && <AiOutlineSearch />}
            isDisabled={isSearching}
            w="150px"
          >
            {isSearching ? <Spinner size="sm" /> : "Search"}
          </Button>
        </Flex>

        {hasHiddenValues(homeData) && homeData?._id && (
          <Button
            mt={4}
            colorScheme="green"
            onClick={() => handleGrantAccess(homeData._id)}
            w="100%"
            isLoading={isGrantingAccess} // Show loading spinner when granting access
            isDisabled={isGrantingAccess} // Disable button while granting access
          >
            Reveal Homeowner Information
          </Button>
        )}

        {homeData && <ZillowHomeReveal homeData={homeData} />} {/* Pass the data to ZillowHomeReveal */}
      </Box>
    </Flex>
  );
};

export default ZillowHome;
