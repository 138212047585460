import React, { useEffect, useState } from 'react';
import { Box, Button, HStack, Flex, useToast } from '@chakra-ui/react';
import { useAuth } from './AuthContext';
import { getAuth, sendEmailVerification, reload } from 'firebase/auth';
import { doc, updateDoc, getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Banner = () => {
    const { userProfile, setUserProfile } = useAuth();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const auth = getAuth();
    const db = getFirestore();
    const functions = getFunctions();

    const handleVerification = async () => {
        setIsLoading(true);
        try {
            await reload(auth.currentUser);
            if (auth.currentUser.emailVerified) {
                const userDocRef = doc(db, 'users', auth.currentUser.uid);
                await updateDoc(userDocRef, { verified: true });
                if (setUserProfile) {
                    setUserProfile((prevProfile) => ({
                        ...prevProfile,
                        verified: true,
                    }));
                }

                // Call the logVerifiedUser function
                const logVerifiedUser = httpsCallable(functions, 'logVerifiedUser');
                await logVerifiedUser();

                toast({
                    title: 'Successfully Verified Account',
                    description: 'Your account has been verified.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
            } else {
                await sendEmailVerification(auth.currentUser);
                toast({
                    title: 'Verification Email Sent',
                    description: `Verification email sent to ${auth.currentUser.email}.`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
            }
        } catch (error) {
            if (error.code === 'auth/too-many-requests') {
                toast({
                    title: 'Too Many Requests',
                    description: 'Please wait 60 seconds before we send you another email.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
            } else {
                toast({
                    title: 'Error',
                    description: error.message,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom',
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (userProfile && userProfile.verified) {
            setIsLoading(false);
        }
    }, [userProfile]);

    if (userProfile) {
        if (userProfile.verified) {
            return null; // Return nothing if the user is verified
        }
    }

    return (
        <Flex
            bg="blue.600"
            p={2}
            color="white"
            justifyContent="center"
            alignItems="center"
        >
            {userProfile ? (
                <HStack spacing={2} textAlign="center">
                    <Box>Please verify your account to continue using scoutstack.</Box>
                    <Button 
                        size="sm"
                        onClick={handleVerification}
                        textAlign="center"
                        isLoading={isLoading}
                    >
                        Verify Now
                    </Button>
                </HStack>
            ) : (
                'Loading user information...'
            )}
        </Flex>
    );
};

export default Banner;
