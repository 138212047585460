import React from "react";
import {
  Box,
  Flex,
  Image,
  Heading,
  Text,
  Stack,
  Link,
  List,
  ListItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";

const LinkedInProfileReveal = ({ profileData }) => {
  return (
    <Box mt={4} p={6} mb={50} borderWidth="1px" borderRadius="md">
      <Flex alignItems="center" mb={4}>
        <Image
          borderRadius="full"
          boxSize="100px"
          src={profileData.profilePic || "N/A"}
          alt={`${profileData.fullName || "N/A"} profile picture`}
          mr={6}
        />
        <Box>
          <Heading size="lg">{profileData.fullName || "N/A"}</Heading>
          <Text fontSize="md" color="gray.600">
            {profileData.title || "N/A"}
          </Text>
          <Text fontSize="md" color="gray.500">
            {profileData.location || "N/A"}
          </Text>
        </Box>
      </Flex>

      {/* Contact Information */}
      <Stack spacing={3} mb={6}>
        {/* Display Phones and Emails in Columns */}
        <SimpleGrid columns={[1, 2]} spacing={4}>
          {/* Phone Numbers */}
          <Box>
            <Flex alignItems="center" mb={2}>
              <HiOutlinePhone style={{ width: 20, height: 20, marginRight: "8px" }} />
              <Heading size="sm">Phone Numbers</Heading>
            </Flex>
            <List spacing={2}>
              {profileData.phones && profileData.phones.length > 0 ? (
                profileData.phones.map((phoneObj, index) => {
                  const phone = Object.keys(phoneObj)[0];
                  return (
                    <ListItem key={index}>
                      <Link href={`tel:${phone}`} color="blue.500" _hover={{ color: "blue.500" }}>
                        {phone}
                      </Link>{" "}
                      - {phoneObj[phone] === "primary" ? "Primary" : "Secondary"}
                    </ListItem>
                  );
                })
              ) : (
                <ListItem>N/A</ListItem>
              )}
            </List>
          </Box>

          {/* Emails */}
          <Box>
            <Flex alignItems="center" mb={2}>
              <HiOutlineMail style={{ width: 20, height: 20, marginRight: "8px" }} />
              <Heading size="sm">Emails</Heading>
            </Flex>
            <List spacing={2}>
              {profileData.emails && profileData.emails.length > 0 ? (
                profileData.emails.map((emailObj, index) => {
                  const email = Object.keys(emailObj)[0];
                  return (
                    <ListItem key={index}>
                      <Link href={`mailto:${email}`} color="blue.500" _hover={{ color: "blue.500" }}>
                        {email}
                      </Link>{" "}
                      - {emailObj[email] === "personal" ? "Personal" : "Work"}
                    </ListItem>
                  );
                })
              ) : (
                <ListItem>N/A</ListItem>
              )}
            </List>
          </Box>
        </SimpleGrid>
      </Stack>

      {/* About Section */}
      <Box mt={4}>
        <Heading size="md" mb={2}>
          About
        </Heading>
        <Text fontSize="md">{profileData.about || "N/A"}</Text>
      </Box>

      {/* Experience Section */}
      <Box mt={4}>
        <Heading size="md" mb={4}>
          Experience
        </Heading>
        {profileData.experiences && profileData.experiences.length > 0 ? (
          profileData.experiences.map((experience, index) => (
            <Box key={index} mb={6}>
              <Flex alignItems="center">
                {experience.logo && (
                  <Image src={experience.logo} boxSize="40px" borderRadius="md" mr={4} />
                )}
                <Box>
                  <Link
                    href={experience.entityUrl}
                    fontWeight="bold"
                    fontSize="lg"
                    isExternal
                    color="blue.500"
                    _hover={{ color: "blue.500" }}
                  >
                    {experience.company || "N/A"}
                  </Link>
                  <Text fontSize="sm">{experience.positions[0]?.title || "N/A"}</Text>
                </Box>
              </Flex>

              {experience.positions.map((position, i) => (
                <Box key={i} mt={2} pl={10}>
                  <Text fontWeight="bold">{position.title || "N/A"}</Text>
                  <Text fontSize="sm" color="gray.600">
                    {position.dateRange || "N/A"} | {position.location || "N/A"}
                  </Text>
                  {position.description && (
                    <Text fontSize="sm" color="gray.500" mt={1}>
                      {position.description}
                    </Text>
                  )}
                </Box>
              ))}
            </Box>
          ))
        ) : (
          <Text>N/A</Text>
        )}
      </Box>

      {/* Education Section */}
      <Box mt={4}>
        <Heading size="md" mb={4}>
          Education
        </Heading>
        {profileData.education && profileData.education.length > 0 ? (
          profileData.education.map((edu, index) => (
            <Flex key={index} alignItems="center" mb={4}>
              {edu.logo && (
                <Image src={edu.logo} boxSize="40px" borderRadius="md" mr={4} />
              )}
              <Box>
                <Text fontWeight="bold">{edu.school || "N/A"}</Text>
                <Text fontSize="sm">{edu.degree || "N/A"}</Text>
                {edu.dateRange && (
                  <Text fontSize="sm" color="gray.600">
                    {edu.dateRange}
                  </Text>
                )}
              </Box>
            </Flex>
          ))
        ) : (
          <Text>N/A</Text>
        )}
      </Box>

      {/* Skills Section */}
      <Box mt={4}>
        <Heading size="md" mb={4}>
          Skills
        </Heading>
        {profileData.skills && profileData.skills.length > 0 ? (
          <Flex wrap="wrap">
            {profileData.skills.map((skill, index) => (
              <Text
                key={index}
                bg="blue.100"
                color="blue.700"
                px={3}
                py={1}
                borderRadius="md"
                mr={2}
                mb={2}
                fontWeight="bold"
              >
                {skill}
              </Text>
            ))}
          </Flex>
        ) : (
          <Text>N/A</Text>
        )}
      </Box>
    </Box>
  );
};

export default LinkedInProfileReveal;
