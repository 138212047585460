import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function Automation() {
  return (
    <Box>
      <Text>Automation</Text>
    </Box>
  );
}

export default Automation;
