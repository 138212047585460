import React, { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, db } from '../../Firebase';
import { doc, onSnapshot, collection } from 'firebase/firestore';
import { Flex, Spinner } from '@chakra-ui/react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [workspacesData, setWorkspacesData] = useState({});
  const [userProfile, setUserProfile] = useState(null);
  const [allowedDocuments, setAllowedDocuments] = useState({});

  useEffect(() => {
    let unsubscribeFromWorkspaces = () => {};
    let unsubscribeFromAllowedDocuments = {};

    const unsubscribeFromAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);

      if (user) {
        const userDocRef = doc(db, 'users', user.uid);
        const unsubscribeFromUserDoc = onSnapshot(userDocRef, (userDoc) => {
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserProfile(userData);

            const { workspaceIds } = userData;
            if (workspaceIds) {
              const workspaceData = {};
              const workspaceListeners = workspaceIds.map((workspaceId) => {
                const workspaceDocRef = doc(db, 'workspaces', workspaceId);
                return onSnapshot(workspaceDocRef, (workspaceDoc) => {
                  if (workspaceDoc.exists()) {
                    workspaceData[workspaceId] = workspaceDoc.data();
                    
                    // Subscribe to allowedDocuments collection
                    const allowedDocsRef = collection(workspaceDocRef, 'allowedDocuments');
                    unsubscribeFromAllowedDocuments[workspaceId] = onSnapshot(allowedDocsRef, (snapshot) => {
                      const docsData = {};
                      snapshot.forEach((doc) => {
                        docsData[doc.id] = doc.data();
                      });
                      setAllowedDocuments(prevState => ({
                        ...prevState,
                        [workspaceId]: docsData
                      }));
                    });
                  } else {
                    delete workspaceData[workspaceId];
                    if (unsubscribeFromAllowedDocuments[workspaceId]) {
                      unsubscribeFromAllowedDocuments[workspaceId]();
                      delete unsubscribeFromAllowedDocuments[workspaceId];
                    }
                  }
                  setWorkspacesData({ ...workspaceData });
                });
              });

              unsubscribeFromWorkspaces = () => {
                workspaceListeners.forEach((unsubscribe) => unsubscribe());
                Object.values(unsubscribeFromAllowedDocuments).forEach(unsubscribe => unsubscribe());
              };
            }
          }
        });

        return () => {
          unsubscribeFromUserDoc();
          unsubscribeFromWorkspaces();
        };
      }
    });

    return () => {
      unsubscribeFromAuth();
      unsubscribeFromWorkspaces();
    };
  }, []);

  if (loading) {
    return (
      <Flex height="100vh" justifyContent="center" alignItems="center">
        <Spinner size="xl" />
      </Flex>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser, workspacesData, userProfile, allowedDocuments }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;