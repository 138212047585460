import React, { useState, useEffect } from 'react';
import { Box, Flex, Image, VStack, IconButton, Tooltip, Text, Menu, MenuButton, MenuList, MenuItem, Button, HStack, Progress, Spinner } from '@chakra-ui/react';
import { Outlet, Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './utils/AuthContext';
import logo from '../assets/logo.png';
import { 
    HiOutlineCog, 
    HiOutlineLightningBolt,
    // HiOutlineClipboardList,
    HiOutlineHome, 
    // HiOutlineSearch,
    HiOutlineLogout,
    HiOutlineCreditCard,
} from "react-icons/hi";
import Banner from './utils/Banner';

const buttons = [
    { to: "/dashboard/home", icon: HiOutlineHome, label: "Home" },
    // { to: "/dashboard/contacts", icon: HiOutlineUsers, label: "Contacts" },
    { to: "/dashboard/tools", icon: HiOutlineLightningBolt , label: "Tools" },
    // { to: "/dashboard/search", icon: HiOutlineSearch  , label: "Search" },
    // { to: "/dashboard/lists", icon: HiOutlineClipboardList , label: "Lists" },
];

const settingsButton = { to: "/dashboard/settings/profile", icon: HiOutlineCog, label: "Settings" };

function Dashboard() {
    const location = useLocation();
    const navigate = useNavigate();
    const { currentUser, workspacesData } = useAuth();

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentUser && workspacesData && Object.keys(workspacesData).length > 0) {
                setIsLoading(false);
                clearInterval(interval);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [currentUser, workspacesData]);

    if (isLoading) {
        return (
            <Flex height="100vh" justifyContent="center" alignItems="center">
                <Spinner size="xl" />
            </Flex>
        );
    }

    const workspaceId = Object.keys(workspacesData)[0];
    const workspace = workspacesData[workspaceId];
    
    const firstLetter = currentUser && currentUser.displayName ? currentUser.displayName.charAt(0).toUpperCase() : '';

    const pathParts = location.pathname.split('/');
    const paramAfterDashboard = pathParts[2] || 'home';
    const capitalizedParam = paramAfterDashboard.charAt(0).toUpperCase() + paramAfterDashboard.slice(1);

    const isFreePlan = workspace?.priceId?.includes('Free');
    const usagePercentage = (workspace?.usage / workspace?.limit) * 100;

    return (
        <Flex height="100vh" direction="column">
            <Banner />
            <Flex flex="1" overflow="hidden">
                {/* Sidebar */}
                <Box color="white" w="80px" borderRight="1px" borderColor="gray.200" position="fixed" height="100vh">
                    <Flex direction="column" align="center" height="100%" justify="space-between">
                        <VStack>
                            <Box w="80px" h="80px" display="flex" justifyContent="center" alignItems="center" borderBottomWidth="1px" mb={2}>
                                <Link to="/dashboard/home">
                                    <Image src={logo} boxSize="40px" />
                                </Link>
                            </Box>
                            {buttons.map(({ to, icon, label }) => (
                                <Tooltip key={label} label={label} aria-label={`${label} tooltip`} placement="right" hasArrow>
                                    <Link to={to}>
                                        <IconButton
                                            h="50px"
                                            color="gray.400"
                                            w="50px"
                                            icon={React.createElement(icon, { size: "26px" })}
                                            aria-label={label}
                                            bg="white"
                                            _hover={{ bg: "gray.100" }}
                                        />
                                    </Link>
                                </Tooltip>
                            ))}
                        </VStack>
                        <Box mb={4}>
                            <Tooltip label={settingsButton.label} aria-label={`${settingsButton.label} tooltip`} placement="right" hasArrow>
                                <Link to={settingsButton.to}>
                                    <IconButton
                                        h="50px"
                                        color="gray.400"
                                        w="50px"
                                        icon={React.createElement(settingsButton.icon, { size: "26px" })}
                                        aria-label={settingsButton.label}
                                        bg="white"
                                        _hover={{ bg: "gray.100" }}
                                    />
                                </Link>
                            </Tooltip>
                        </Box>
                    </Flex>
                </Box>
                
                {/* Main content */}
                <Box flex="1" ml="80px" overflow="hidden">
                    {/* Header */}
                    <Flex h="80px" alignItems="center" justifyContent="space-between" borderBottomWidth="1px" borderColor="gray.200" p={4} position="fixed" width="calc(100% - 80px)" bg="white" zIndex={1}>
                        <Text fontSize="2xl" fontWeight="bold">
                            {capitalizedParam}
                        </Text>
                        <HStack spacing={4} alignItems="center">
                            {isFreePlan && (
                                <Button colorScheme="blue" borderRadius="50px" size="sm" as={Link} to="/dashboard/settings/billing">
                                    Upgrade
                                </Button>
                            )}
                            {workspace && (
                                <Box>
                                    <Text fontSize="sm" textAlign="right" w="150px">
                                     <strong> {workspace.usage} / {workspace.limit} Credits</strong> 
                                    </Text>
                                    <Progress
                                        colorScheme={usagePercentage >= 100 ? "red" : "blue"}
                                        value={usagePercentage}
                                        size="sm"
                                        bg="gray.200"
                                        borderRadius="full"
                                    />
                                </Box>
                            )}
                            {currentUser && (
                                <Menu>
                                    <MenuButton
                                        as={Button}
                                        rounded="full"
                                        variant="link"
                                        cursor="pointer"
                                        minW={0}
                                        bg="gray.200"
                                        color="black"
                                        h="40px"
                                        w="40px"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        _hover={{ transform: 'scale(1.1)' }}
                                        transition="transform 0.2s"
                                    >
                                        <Text fontSize="lg" fontWeight="bold" textDecoration="none">
                                            {firstLetter}
                                        </Text>
                                    </MenuButton>
                                    <MenuList>
                                    <MenuItem icon={<HiOutlineCreditCard size="20px"/>} onClick={() => navigate('/dashboard/settings/billing')}>
                                            Pricing Plans
                                        </MenuItem>
                                        <MenuItem icon={<HiOutlineCog size="20px"/>} onClick={() => navigate('/dashboard/settings/profile')}>
                                            Settings
                                        </MenuItem>
                                        <MenuItem icon={<HiOutlineLogout  size="20px"/>} onClick={() => navigate('/dashboard/settings/profile')}>
                                            Logout
                                        </MenuItem>
                                    </MenuList>
                                </Menu>
                            )}
                        </HStack>
                    </Flex>
                    
                    {/* Scrollable Content */}
                    <Box mt="80px" p={4} height="calc(100vh - 80px)" overflowY="auto">
                        <Outlet />
                    </Box>
                </Box>
            </Flex>
        </Flex>
    );
}

export default Dashboard;
