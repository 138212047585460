
const yearlyPricingTiers = [
    {
      title: 'Free',
      price: '$0',
      priceId: 'Free',
      features: ['25 Credits', 'Free forever'],
      isPopular: false,
      limit: 25,
    },
    {
      title: 'Basic',
      price: '$49',
      priceId: 'price_1PoTf4AT4690HpL3LtmEekqb',
      features: ['3,000 Credits per year', 'Add users to workspace'],
      isPopular: false,
      limit: 3000,
    },
    {
      title: 'Pro',
      price: '$199',
      priceId: 'price_1PoTfdAT4690HpL3P3hx31R7',
      features: ['18,000 Credits per year', 'Add users to workspace', 'Priority support'],
      isPopular: true,
      limit: 18000,
    },
    {
      title: 'Premium',
      price: '$399',
      priceId: 'price_1PoTg1AT4690HpL3QSAhP3Zx',
      features: ['60,000 Credits per year', 'Add users to workspace', 'White glove support'],
      isPopular: false,
      limit: 60000,
    },
  ];
  
const monthlyPricingTiers = [
    {
      title: 'Free',
      price: '$0',
      priceId: 'Free',
      features: ['25 Credits', 'Free forever'],
      isPopular: false,
      limit: 25,
    },
    {
      title: 'Basic',
      price: '$69',
      priceId: 'price_1PmuJuAT4690HpL3txgoer13',
      features: ['250 Credits per month', 'Add users to workspace'],
      isPopular: false,
      limit: 250,
    },
    {
      title: 'Pro',
      price: '$249',
      priceId: 'price_1PmuK7AT4690HpL3Vcj5sqRy',
      features: ['1,500 Credits per month', 'Add users to workspace', 'Priority support'],
      isPopular: true,
      limit: 1500,
    },
    {
      title: 'Premium',
      price: '$499',
      priceId: 'price_1PmuKyAT4690HpL3cNY4zm6e',
      features: ['5,000 Credits per month', 'Add users to workspace', 'White glove support'],
      isPopular: false,
      limit: 5000,
    },
  ];
  
  
  const Prices = ({ billingCycle }) => {
    return billingCycle === 'monthly' ? monthlyPricingTiers : yearlyPricingTiers;
  };
  
  export default Prices;
  