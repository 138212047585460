import React from 'react';
import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { Outlet, Link, useLocation } from 'react-router-dom';

function Settings() {
  const location = useLocation();
  const currentTab = location.pathname.split('/').pop();

  return (
    <Box p="4">
      <Tabs index={['profile', 'billing', 'workspace'].indexOf(currentTab)} isLazy>
        <TabList display="inline-flex">
          <Tab as={Link} to="/dashboard/settings/profile" _selected={{ color: 'blue.500', borderColor: 'blue.500' }}>
            Profile
          </Tab>
          <Tab as={Link} to="/dashboard/settings/billing" _selected={{ color: 'blue.500', borderColor: 'blue.500' }}>
            Billing
          </Tab>
          {/* <Tab as={Link} to="/dashboard/settings/workspace" _selected={{ color: 'blue.500', borderColor: 'blue.500' }}>
            Workspace
          </Tab> */}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Outlet />
          </TabPanel>
          <TabPanel>
            <Outlet />
          </TabPanel>
          <TabPanel>
            <Outlet />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default Settings;
