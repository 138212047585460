import React, { useState } from "react";
import { Box, Text, Stack, Collapse, Button, Divider, Flex, Link } from "@chakra-ui/react";
import { HiPhone, HiMail, HiLocationMarker } from "react-icons/hi";

const ZillowHomeReveal = ({ homeData }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleCollapse = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <Box mb={50}>
      {/* People Section */}
      {homeData.people && homeData.people.length > 0 ? (
        <Stack spacing={4} mt={4}>
          {homeData.people.map((person, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" boxShadow="md">
              {/* Name and Age Section */}
              <Flex justify="space-between" align="center" mb={2}>
                <Text fontSize="xl" fontWeight="bold">
                  {person.name}
                </Text>
                <Text fontSize="xl" fontWeight="bold" color="gray.600">
                  Age: {person.age}
                </Text>
              </Flex>
              <Divider />
              
              {person.primaryPhone && person.primaryPhone.number && person.primaryPhone.number.trim() !== "" && (
                <Flex align="center" mt={2}>
                    <HiPhone size="20px" />
                    <Text ml={2} fontWeight="bold">Primary Phone:</Text>
                    <Link href={`tel:${person.primaryPhone.number}`} color="blue.500" ml={2}>
                    {person.primaryPhone.number} ({person.primaryPhone.type})
                    </Link>
                </Flex>
                )}

              
              {person.email && (
                <Flex align="center" mt={2}>
                  <HiMail size="20px" />
                  <Text ml={2} fontWeight="bold">Email:</Text>
                  <Link href={`mailto:${person.email}`} color="blue.500" ml={2}>
                    {person.email}
                  </Link>
                </Flex>
              )}
              
              {person.address && (
                <Flex align="center" mt={2}>
                  <HiLocationMarker size="20px" />
                  <Text ml={2} fontWeight="bold">Address:</Text>
                  <Text ml={2}>{person.address}</Text>
                </Flex>
              )}

              {/* Other Phones (Dropdown with Border Bottom) */}
              {person.otherPhones && person.otherPhones.length > 0 && (
                <Box mt={4} borderBottom="1px solid" borderColor="gray.200">
                  <Button
                    leftIcon={<HiPhone size="24px" />}
                    onClick={() => toggleCollapse(`phones-${index}`)}
                    size="md"
                    fontSize="18px"
                    variant="ghost"
                    _hover={{ bg: "gray.100" }}
                    w="100%"
                    justifyContent="flex-start"
                  >
                    Other Phones
                  </Button>
                  <Collapse in={openIndex === `phones-${index}`} animateOpacity>
                    <Box p={2} mt={2}>
                      {person.otherPhones.map((phone, i) => (
                        <Text key={i}>
                          <Link href={`tel:${phone.number}`} color="blue.500">
                            {phone.number} ({phone.type})
                          </Link> - First Reported: {phone.firstReported}
                        </Text>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              )}

              {/* Other Emails (Dropdown with Border Bottom) */}
              {person.other_emails && person.other_emails.length > 0 && (
                <Box mt={4} borderBottom="1px solid" borderColor="gray.200">
                  <Button
                    leftIcon={<HiMail size="24px" />}
                    onClick={() => toggleCollapse(`emails-${index}`)}
                    size="md"
                    fontSize="18px"
                    variant="ghost"
                    _hover={{ bg: "gray.100" }}
                    w="100%"
                    justifyContent="flex-start"
                  >
                    Other Emails
                  </Button>
                  <Collapse in={openIndex === `emails-${index}`} animateOpacity>
                    <Box p={2} mt={2}>
                      {person.other_emails.map((email, i) => (
                        <Text key={i}>
                          <Link href={`mailto:${email}`} color="blue.500">
                            {email}
                          </Link>
                        </Text>
                      ))}
                    </Box>
                  </Collapse>
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      ) : (
        <Text>No people associated with this address.</Text>
      )}
    </Box>
  );
};

export default ZillowHomeReveal;
