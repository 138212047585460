import React, { useState } from 'react';
import { Box, Text, VStack, Button, Spinner } from '@chakra-ui/react';
import PricingTable from './PricingTable';
import Prices from './Prices';
import { useAuth } from '../../utils/AuthContext';
import { getFunctions, httpsCallable } from 'firebase/functions';

function Billing() {
  const { currentUser, workspacesData } = useAuth();
  const functions = getFunctions();
  const [isLoading, setIsLoading] = useState(false);

  if (!currentUser) {
    return <Text>Loading...</Text>;
  }

  const workspaceIds = Object.keys(workspacesData);
  if (workspaceIds.length === 0) {
    return <Text>No workspaces found.</Text>;
  }

  const currentWorkspaceId = workspaceIds[0];
  const currentWorkspace = workspacesData[currentWorkspaceId];

  const handleManageBilling = async () => {
    setIsLoading(true);
    const manageBilling = httpsCallable(functions, 'createScoutStackPortal');
    try {
      const response = await manageBilling({ customerId: currentWorkspace.customerId });
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating billing portal session:', error.message);
      setIsLoading(false);
    }
  };

  const formatDate = (epochTime) => {
    if (!epochTime) return null;
    const date = new Date(epochTime * 1000);
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const getPriceDetails = (priceId) => {
    const yearlyPrices = Prices({ billingCycle: 'yearly' });
    const monthlyPrices = Prices({ billingCycle: 'monthly' });

    const findPrice = (prices, priceId) => prices.find(price => price.priceId === priceId);

    let priceDetails = findPrice(yearlyPrices, priceId);
    if (priceDetails) {
      return { ...priceDetails, billingCycle: 'yearly' };
    }

    priceDetails = findPrice(monthlyPrices, priceId);
    if (priceDetails) {
      return { ...priceDetails, billingCycle: 'monthly' };
    }

    return { title: 'Free', billingCycle: 'free' };
  };

  const priceDetails = getPriceDetails(currentWorkspace.priceId[0]);
  const subscriptionEndDate = formatDate(currentWorkspace.subscriptionEndDate);

  return (
    <Box
      display="flex"
      flexDirection="column"
    >
      <VStack align="left" overflowY="auto" spacing={4}>
        <Text fontSize="2xl" fontWeight="bold">
          Billing
        </Text>
        <Text fontSize="lg">
          <strong>You are on the:</strong> {priceDetails.title === 'Free' ? 'Free Plan' : `${priceDetails.title} ${priceDetails.billingCycle.charAt(0).toUpperCase() + priceDetails.billingCycle.slice(1)} Plan`}
        </Text>
        {subscriptionEndDate && (
          <Text fontSize="lg">
            <strong>Your subscription renews:</strong> {subscriptionEndDate}
          </Text>
        )}
        {currentWorkspace.customerId && (
          <Button
            onClick={handleManageBilling}
            isDisabled={isLoading}
            height="auto"
            py="2"
            px="3"
            fontSize="xl"
            fontWeight="bold"
            colorScheme="blue"
            width="100%"
            maxW="180px"
          >
            {isLoading ? <Spinner size="md" /> : 'Manage Billing'}
          </Button>
        )}
        <PricingTable />
      </VStack>
    </Box>
  );
}

export default Billing;