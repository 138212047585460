import React from 'react';
import { Box, Text } from '@chakra-ui/react';

function CompanyChanges() {
  return (
    <Box>
      <Text>Company Changes Intent</Text>
    </Box>
  );
}

export default CompanyChanges;
