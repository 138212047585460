import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { AuthProvider, useAuth } from './components/utils/AuthContext';
import PrivateRoute from './components/utils/PrivateRoute';
import DirectRoute from './components/utils/DirectRoute';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Search from './components/dashboard/Search';
import Settings from './components/dashboard/settings-page/Settings';
import Home from './components/dashboard/home-page/Home';
import Intent from './components/dashboard/Intent';
import Automation from './components/dashboard/Automation';
import Contacts from './components/dashboard/Contacts';
import JobSearch from './components/dashboard/intent/JobSearch';
import CompanyChanges from './components/dashboard/intent/CompanyChanges';
import Funding from './components/dashboard/intent/Funding';
import Profile from './components/dashboard/settings-page/Profile';
import Billing from './components/dashboard/settings-page/Billing';
import Workspace from './components/dashboard/settings-page/Workspace';
import Tools from './components/dashboard/tools-page/Tools';
import ZillowHome from './components/dashboard/tools-page/cards/ZillowHome';
import LinkedInProfile from './components/dashboard/tools-page/cards/LinkedinProfile';
import Lists from './components/dashboard/lists/Lists';

const theme = extendTheme({
  fonts: {
    heading: "Reddit Sans, sans-serif",
    body: "Reddit Sans, sans-serif",
  },
  colors: {
    blue: {
      400: '#60aeff',
      500: '#2a93ff',
      600: '#0060c3',
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route
              path="/login"
              element={
                <DirectRoute>
                  <Login />
                </DirectRoute>
              }
            />
            <Route
              path="/signup"
              element={
                <DirectRoute>
                  <SignUp />
                </DirectRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            >
              <Route index element={<Home />} />
              <Route path="home" element={<Home />} />
              <Route path="tools" element={<Tools />} />
              <Route path="tools/zillow-home" element={<ZillowHome />} />
              <Route path="tools/linkedin-profile" element={<LinkedInProfile />} />
              <Route path="lists" element={<Lists />} />

              <Route path="intent" element={<Intent />} />
              <Route path="intent/job-search" element={<JobSearch />} />
              <Route path="intent/company-changes" element={<CompanyChanges />} />
              <Route path="intent/funding" element={<Funding />} />
              <Route path="contacts" element={<Contacts />} />
              <Route path="automation" element={<Automation />} />
              <Route path="search" element={<Search />} />

              <Route path="settings" element={<Settings />}>
                <Route index element={<Navigate to="profile" />} />
                <Route path="profile" element={<Profile />} />
                <Route path="billing" element={<Billing />} />
                <Route path="workspace" element={<Workspace />} />
              </Route>
            </Route>
            <Route path="*" element={<AuthRedirect />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ChakraProvider>
  );
}

function AuthRedirect() {
  const { currentUser } = useAuth();
  return <Navigate to={currentUser ? "/dashboard" : "/login"} />;
}

export default App;
